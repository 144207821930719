import React from 'react'
import Header from '../Header'
import Footer from '../Footer'
const Container = ({children}) => {

    return(

        <div className='container_page'>  
            {children}

            <div style={{ height: '6rem' }}></div>
            <Footer />

        </div>

    )
}

export default Container