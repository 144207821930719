import React, { useContext } from 'react'
import ContainterPageAcessories from '../../components/ContainterPageAcessories'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import Filter from '../../components/Filter'
import ContainerRowFilter from '../../components/ContainerRowFilter'

import { CartContext } from '../../context/cart'
import HeaderMobile from '../../components/HeaderMobile'
import ContainerRowAcessories from '../../components/ContainerRowAcessories'
const Accessories = () => {

    const { cart, cartLenght, stateCart, handleAddToCart } = useContext(CartContext)

    return (
        <ContainterPageAcessories>
            <Header />
            <HeaderMobile/>
            <HeaderSecondary />
            <ContainerRowFilter>
                <Filter />
                <ContainerRowAcessories>
       
                </ContainerRowAcessories>
            </ContainerRowFilter>
        </ContainterPageAcessories>
    )
}

export default Accessories