import React from 'react'

const ContainterPageAcessories = ({children}) => {
    return(

        <div className='container_page_acessories'>
            {children}
        </div>

    )
}

export default ContainterPageAcessories