import React from 'react'
import Container from '../../components/Container'
import Header from '../../components/Header'
import HeaderMobile from '../../components/HeaderMobile'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerPayment from '../../components/ContainerPayment'
import SidebarCart from '../../components/SidebarCart'
import PaymentIcons from '../../components/PaymentIcons'
import ContainerAccount from '../../components/ContainerAccount'
const Identification = () => {
    return (
        <Container>
            <Header />
            <HeaderMobile />
            <HeaderSecondary/>
            <PaymentIcons type={2}/>
            <ContainerAccount route="/payment/identification"/>
            <SidebarCart/>
        </Container>
    )
}

export default Identification