import React from 'react'

const ContainerRowFilter = ({children}) => {
    return(
        
        <div className='container_row_filter'>
            {children}
            
        </div>

    )
}

export default ContainerRowFilter