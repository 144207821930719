import React, { useContext, useEffect, useState } from 'react';
import api from '../../services/api';
import Container from '../../components/Container';
import Header from '../../components/Header';
import SidebarCart from '../../components/SidebarCart';
import HeaderSecondary from '../../components/HeaderSecondary';
import Carrousel from '../../components/Carrousel';
import ContainerCard from '../../components/ContainerCard';
import ContainerCarrousel from '../../components/ContainerCarrousel';
import Card from '../../components/Card';
import toyotaLogo from '../../images/svg/toyota-logo.svg';
import { CartContext } from '../../context/cart';
import HeaderMobile from '../../components/HeaderMobile';
import CardCarrousel from '../../components/CardCarrousel';
import { useNavigate } from 'react-router-dom';
import bannerIcon from '../../images/png/3456-7890 (2).jpg'

import VerticalCarrousel from '../../components/VerticalCarrousel';
import SidebarFilter from '../../components/SidebarFilter';
import { FilterContext } from "../../context/filter";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // certifique-se de importar os estilos do carousel

const Landing = () => {
    const navigate = useNavigate();
    const { cart, cartLenght, stateCart, handleAddToCart } = useContext(CartContext);
    const { dataFilterProducts } = useContext(FilterContext)
    const [filterItems, setFilterItems] = useState([])

    const [dataPrimary, setDataPrimary] = useState([]);
    const [dataSecondary, setDataSecondary] = useState([]);
    const [dataThree, setDataThree] = useState([]);
    const [banners, setBanners] = useState([]);


    const getPrimaryProducts = async () => {
        const response = await api.get('client/get/all/product/Produtos em destaque');
        if (response.status === 200) {
            setDataPrimary(response.data);
        }
    };



    useEffect(() => {

        const convert = JSON.parse(localStorage.getItem("@FILTER_DATA"))
        setFilterItems(convert)


    }, [])

    useEffect(() => {
        getPrimaryProducts();
    }, []);


    const getBanner = async () => {
        try {
            const response = await api.get("client/banner/2");
            if (response.status === 200) {
                setBanners(response.data);
            }
        } catch (error) {
            console.error("Error fetching banners:", error);
        }
    };

    useEffect(() => {
        getBanner();
    }, []);

    return (
        <Container>
            <Header data={cartLenght} />
            <HeaderMobile />
            <HeaderSecondary />

            <SidebarFilter />

            <div className='carrousel_mobile'>
                <Carousel infiniteLoop autoPlay stopOnHover emulateTouch showThumbs={false} showArrows>

                    {banners?.map(item => {
                        return (

                            <div>
                                <img src={item.image} alt="Banner Primary" />
                            </div>
                        )
                    })}

                </Carousel>
            </div>

            <SidebarCart state={true} />
            <ContainerCarrousel>
                <Carrousel />
                <VerticalCarrousel />
            </ContainerCarrousel>

            <div id='bottom_search'></div>

            {String(dataFilterProducts).length == 0

                ?

                <>
                    {dataPrimary.length > 0 && (
                        <>
                            <ContainerCard name="Produtos em destaque" />
                            <CardCarrousel key={1} data={dataPrimary.length}>
                                {dataPrimary.map((item) => (
                                    <div className='container_items_mobile_carrousel' key={item.product_id}>
                                        <Card
                                            onClick={() => handleAddToCart(item)}
                                            product_image={item.product_image}
                                            topImage={toyotaLogo}
                                            weight={item.weight}
                                            promotion_price={item.promotion_price}
                                            name={item.name}
                                            category_image={item.category_image}
                                            product_id={item.product_id}
                                            description={item.description}
                                            price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                            image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                        />
                                    </div>
                                ))}
                            </CardCarrousel>
                        </>
                    )}


                    {dataSecondary.length > 0 && (
                        <>
                            <ContainerCard name="Engates com Rabicho Fixo" />
                            <CardCarrousel key={2} data={dataSecondary.length}>
                                {dataSecondary.map((item) => (
                                    <div className='container_items_mobile_carrousel' key={item.product_id}>
                                        <Card
                                            onClick={() => handleAddToCart(item)}
                                            product_image={item.product_image}
                                            topImage={toyotaLogo}
                                            weight={item.weight}
                                            promotion_price={item.promotion_price}
                                            name={item.name}
                                            category_image={item.category_image}
                                            product_id={item.product_id}
                                            description={item.description}
                                            price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                            image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                        />
                                    </div>
                                ))}
                            </CardCarrousel>
                        </>
                    )
                    }

                    {dataThree.length > 0 && (
                        <>
                            <ContainerCard name="Engates com Rabicho Removível" />
                            <CardCarrousel key={2} data={dataSecondary.length}>
                                {dataSecondary.map((item) => (
                                    <div className='container_items_mobile_carrousel' key={item.product_id}>
                                        <Card
                                            onClick={() => handleAddToCart(item)}
                                            product_image={item.product_image}
                                            topImage={toyotaLogo}
                                            weight={item.weight}
                                            promotion_price={item.promotion_price}
                                            name={item.name}
                                            category_image={item.category_image}
                                            product_id={item.product_id}
                                            description={item.description}
                                            price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                            image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                        />
                                    </div>
                                ))}
                            </CardCarrousel>
                        </>
                    )
                    }


                </>
                :
                <>
                    <ContainerCard name={localStorage.getItem("@TYPE_ENGATE_FILTER") == "1" ? "Engates com rabicho Fixo" : "Engate com rabhico removivel"} />

                    <CardCarrousel key={3} data={dataSecondary.length}>

                        {dataFilterProducts?.map(item => {
                            return (


                                <div className='container_items_mobile_carrousel' key={item.product_id}>
                                    <Card
                                        onClick={() => handleAddToCart(item)}
                                        product_image={item.product_image}
                                        topImage={toyotaLogo}
                                        weight={item.weight}
                                        promotion_price={item.promotion_price}
                                        name={item.name}
                                        category_image={item.category_image}
                                        product_id={item.product_id}
                                        description={item.description}
                                        price={Number(item.price).toLocaleString('pt-br', { style: 'decimal', minimumFractionDigits: 2 })}
                                        image="https://firebasestorage.googleapis.com/v0/b/storage-tkg.appspot.com/o/2240a979b0eddbab2223d5aae5a1d99e.webp?alt=media&token=041b005b-397f-41d3-936a-ee57269e2e95"
                                    />
                                </div>

                            )

                        })}
                    </CardCarrousel>

                </>
            }


        </Container>
    );
};

export default Landing;
