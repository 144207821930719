import React, { createContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const CarrouselContext = createContext({});

function CarrouselProvider({ children }) {
    
    

    const carrouselRef = useRef(uuidv4())

    const handleRightClick = () => {
        console.log(carrouselRef)
        carrouselRef.current.scrollLeft += carrouselRef.current.offsetWidth
    }

    const handleLeftClick = () => {
        carrouselRef.current.scrollLeft -= carrouselRef.current.offsetWidth

    }


    return (
        <CarrouselContext.Provider value={{ carrouselRef, handleRightClick,handleLeftClick }}>
            {children}
        </CarrouselContext.Provider>
    );
}

export default CarrouselProvider;
