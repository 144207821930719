import React from 'react'

const ContainerRowAcessories = ({children}) => {
    return(

        <div className='container_row_acessories'>
            {children}
        </div>

    )
}

export default ContainerRowAcessories