import { create } from 'apisauce'

const api = create({
    baseURL: 'https://api-nvj5.onrender.com/'
})


api.addAsyncRequestTransform(response => async() =>{
    
    const token = localStorage.getItem('@USER_TOKEN')

    if(token){

        response.headers['Authorization']  = `Bearer ${token}`

    }

})

export default api