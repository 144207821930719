import React from 'react'

const SelectFilter = ({option,onChange}) => {
    return(

        <select onChange={onChange} className='select_filter'>
        {option}
        </select>

    )
}

export default SelectFilter