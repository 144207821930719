import React from 'react'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerPage from '../../components/Container'
import ContainerAccount from '../../components/ContainerAccount'
import HeaderMobile from '../../components/HeaderMobile'
const Account = () => {
    return (

        <ContainerPage>
            <Header />
            <HeaderMobile/>
            <HeaderSecondary />
            <ContainerAccount />
        </ContainerPage>

    )
}

export default Account