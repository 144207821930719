import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import dropdownIcon from '../../images/svg/dropdownIcon.svg'
import searchIcon from '../../images/svg/searchIcon.svg'
import userIcon from '../../images/svg/userIcon.svg'
import { CartContext } from '../../context/cart'
import { BigHead } from '@bigheads/core'
import api from '../../services/api'
import 'animate.css';
import { FilterContext } from '../../context/filter';

const HeaderSecondary = () => {

    const {handleOpenFilterProduct} = useContext(FilterContext)

    const [products, setProducts] = useState([])
    const [name, setName] = useState()
    const [search, setSearch] = useState(false)
    const [categorys,setCategorys] = useState([])

    const { handleStateCart } = useContext(CartContext)



    const navigate = useNavigate()

    const redirectToHomePage = () => {
        navigate("/")
    }

    const redirectToAcessoriePage = () => {

        // navigate("/accessories")
    }

    const redirectToBlogPage = () => {
        navigate("/blog")
    }

    const redirectToOfferPage = () => {
        navigate("/offer")

    }

    const redirectToProductPage = async (id, description, price, image, name, promotion_price) => {
        const response = await api.get(`client/get/all/images/product/${id}`)
        if (response.status == 200) {
            localStorage.setItem("@PRODUCT_IMAGES", JSON.stringify(response.data))
            localStorage.setItem("@NAME", name)
            localStorage.setItem("@DESCRIPTION", description)
            localStorage.setItem("@PRICE", price)
            localStorage.setItem("@PROMOTIONAL_PRICE", promotion_price)
            localStorage.setItem("@IMAGE", image)
            window.location.href = "/product"
        }
    }

    const getProducts = async () => {

        const response = await api.get(`/client/get/product/by/name/${name}`)
        if (response.status == 200) {
            setProducts(response.data)
            return false
        }

        if (response.status == 404) {
            setProducts([])
        }

    }

    const getAllCategorys = async () => {
        const response = await api.get("/client/get/all/categorys/menu")
        if(response.status == 200){
            setCategorys(response.data)
            return false
        }

        setCategorys([])



    }


    const hanldeOnblurInput = () => {

        setTimeout(() => {
            setSearch(false)
        }, [1000])

    }

    useEffect(() => {
        if (name !== undefined && name !== "") {
            // Você pode colocar qualquer lógica que queira executar quando name não for indefinido ou vazio
            return;
        } else {
            const timer = setTimeout(() => {
                setSearch(false);
            }, 5000);

            return () => clearTimeout(timer); // Função de limpeza para limpar o timeout
        }
    }, [search, name]);


    useEffect(() => {

        getProducts()

    }, [name])

    useEffect(() => {

        getAllCategorys()
    },[])

    return (

        <div className="container_header_secondary">


            <div className='box_header_secondary'>

                <div class="dropdown">
                    <button class="btn_dropdow " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        Produtos
                        <img src={dropdownIcon} />
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {categorys?.map(item => {
                            return(
                                <li><a class="dropdown-item" onClick={() => {handleOpenFilterProduct();localStorage.setItem("@TYPE_ENGATE_FILTER",item.name)}}>{item.name}</a></li>

                            )
                        })}
                    </ul>
                </div>


                {search == true

                    ?

                    <div className='container_search_header'>

                        {name

                            ?

                            <div className='bottom_search_header'>
                                {
                                    products?.map(item => {
                                        return (
                                            <div onClick={() => redirectToProductPage(item.product_id, item.description, item.price, item.product_image, item.name, item.promotion_price)} className='container_product_bottom_search_header animate__animated animate__fadeIn'>
                                                <img src={item.product_image} />
                                                <div>
                                                    <p>{item.name}  </p>
                                                    <p><s>R$ {Number(item.promotion_price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</s></p>
                                                    <p>R$ {Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>

                                                </div>
                                            </div>

                                        )
                                    })
                                }

                            </div>

                            :
                            <></>
                        }



                    </div>

                    :
                    <nav>
                        <ul>
                            <li className='li_header_secondary' onClick={() => redirectToHomePage()}>Home</li>
                            <li className='li_header_secondary' onClick={() => redirectToAcessoriePage()}>Acessórios</li>
                            <li className='li_header_secondary' onClick={() => redirectToOfferPage()}>Ofertas</li>
                            <li className='li_header_secondary' onClick={() => redirectToBlogPage()}>Blog</li>
                            <li className='li_header_secondary'>Carrinho</li>
                            <li className='li_header_secondary'>Contato</li>

                        </ul>
                    </nav>
                }




                <div className='header_search' >


                    <div onClick={() => setSearch(true)} style={{ width: search == true ? "980px" : "" }} className='search_item_space'>
                        <img src={searchIcon} onClick={() => setSearch(true)} />
                        {search == true

                            ?
                            <input type='text' autoFocus onBlur={() => hanldeOnblurInput()} onChange={(e) => setName(e.target.value)} placeholder='Pesquise aqui...' />

                            :
                            <></>
                        }

                    </div>

                    <div onClick={() => setSearch(true)} style={{ width: search == true ? "100%" : "" }} className='search_item_space_mobile'>
                        <img src={searchIcon} onClick={() => setSearch(true)} />
                        <p></p>
                    </div>


                </div>


                {search == true

                    ?
                    <input type='text' className='bottom_input_mobile_search' autoFocus onBlur={() => hanldeOnblurInput()} onChange={(e) => setName(e.target.value)} placeholder='Pesquise aqui...' />

                    :
                    <></>
                }


                {name && search == true

                    ?

                    <div className='bottom_search_header_mobile'>
                        {
                            products?.map(item => {
                                return (
                                    <div onClick={() => redirectToProductPage(item.product_id, item.description, item.price, item.product_image, item.name, item.promotion_price)} className='container_product_bottom_search_header animate__animated animate__fadeIn'>
                                        <img src={item.product_image} />
                                        <div>
                                            <p>{item.name}  </p>
                                            <p><s>R$ {Number(item.promotion_price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</s></p>
                                            <p>R$ {Number(item.price).toLocaleString("pt-BR", { minimumFractionDigits: 2 })}</p>

                                        </div>
                                    </div>

                                )
                            })
                        }

                    </div>

                    :
                    <></>
                }


            </div>

        </div>

    )
}

export default HeaderSecondary