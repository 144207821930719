import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppStack from './routes/AppStack';
import CartProvider from './context/cart';
import FilterProvider from './context/filter';
import CarrouselProvider from './context/carrousel';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FilterProvider>
    <CarrouselProvider>
    <CartProvider>
    <AppStack />
    </CartProvider>
    </CarrouselProvider>
    </FilterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
