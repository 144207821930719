import React from 'react'
import { useLottie } from "lottie-react";
import animation from '../../json/Animation - 1723751040865.json'
const Animation = () => {

    const options = {
        animationData: animation,
        loop: true,
        autoplay: true, // Ensure autoplay is enabled

    };
    const { View } = useLottie(options);
    return(
        <div style={{margin: "0 auto"}}>
        {View }
        </div>
    )
}

export default Animation