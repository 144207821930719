import React, { useState, useEffect } from 'react';
import api from '../../services/api';

const Carrousel = () => {
    const [banners, setBanners] = useState([]);

    const getBanner = async () => {
        try {
            const response = await api.get("client/banner/2");
            if (response.status === 200) {
                setBanners(response.data);
            }
        } catch (error) {
            console.error("Error fetching banners:", error);
        }
    };

    useEffect(() => {
        getBanner();
    }, []);

    return (
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-indicators">
                {banners.map((item, index) => (
                    <button
                        key={item.id} // Assumindo que `item` tem um id único
                        type="button"
                        data-bs-target="#myCarousel"
                        data-bs-slide-to={index}
                        className={index === 0 ? 'active' : ''}
                        aria-current={index === 0 ? 'true' : 'false'}
                        aria-label={`Slide ${index + 1}`}
                    ></button>
                ))}
            </div>
            <div className="carousel-inner">
                {banners.map((item, index) => (
                    <div
                        key={item.id} // Assumindo que `item` tem um id único
                        className={`carousel-item ${index === 0 ? 'active' : ''}`}
                    >
                        <img src={item.image} className="d-block w-100" alt={`Slide ${index + 1}`} />
                    </div>
                ))}
            </div>
            <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="prev"
            >
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#myCarousel"
                data-bs-slide="next"
            >
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    );
};

export default Carrousel;
