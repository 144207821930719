import React, { useContext, useEffect, useState } from 'react';
import closeIcon from '../../images/png/close.png';
import { CartContext } from '../../context/cart';
import 'animate.css';
import {useNavigate } from 'react-router-dom';

const SidebarCart = ({ state }) => {
    const navigate = useNavigate()
    const { handleStateCart, handleRemoveItem, handleUpdateQuantity, cart, price, stateCart } = useContext(CartContext);

    const handleBtn = () => {
        navigate("/payment")
    }
    
    return (
        stateCart === true
            ?
            <div className='container_sidebar_cart'>
                <div className='header_sidebar_cart'>
                    <img src={closeIcon} onClick={() => handleStateCart(false)} />
                    <p>Meu Carrinho</p>
                </div>

                <div className='container_cards_sidebar_cart'>
                <div className='container_cards_sidebar_cart'>
                    <div className='container_card_product'>
                        {cart?.map((item, index) => (
                            <div key={index} className='animate__animated  animate__fadeInDown'>
                                <button className='btn_remove_item_cart' onClick={() => handleRemoveItem(index)}>

                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_14_486)">
                                            <path d="M8.23718 7.00022L13.7436 1.49438C14.0854 1.15257 14.0854 0.598385 13.7436 0.256602C13.4018 -0.0852084 12.8476 -0.0852084 12.5059 0.256602L6.99998 5.76302L1.49413 0.256602C1.15232 -0.0852084 0.598141 -0.0852084 0.256358 0.256602C-0.0854252 0.598412 -0.0854525 1.15259 0.256358 1.49438L5.76278 7.00022L0.256358 12.5061C-0.0854525 12.8479 -0.0854525 13.4021 0.256358 13.7439C0.598168 14.0857 1.15235 14.0857 1.49413 13.7439L6.99998 8.23742L12.5058 13.7439C12.8476 14.0857 13.4018 14.0857 13.7436 13.7439C14.0854 13.4021 14.0854 12.8479 13.7436 12.5061L8.23718 7.00022Z" fill="black" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_14_486">
                                                <rect width="14" height="14" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>


                                </button>
                                {item.image != undefined 
                                
                                ?
                            
                                <img src={item.image} alt={item.name} />
                                :
                                <img src={item.product_image} alt={item.name} />

                                }
                                
                                {item.product_name 
                                
                                ?
                                <>
                                <p>{item.product_name}</p>
                                <p>{item.name}:{item.value}</p>
                                </>
                                :
                                <p>{item.name}</p>

                                }
                                
                                
                                <p>{Number(item.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                                <div className='container_plus'>
                                    <button className='button_plus' onClick={() => handleUpdateQuantity(index, item.quantity + 1)}>+</button>
                                    <p>{item.quantity}</p>
                                    <button className='button_minus' onClick={() => handleUpdateQuantity(index, item.quantity - 1)}>-</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                </div>
                
                <div className='bottom_price_sidebar'>
                    <p className='total_cart'>Total: {price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                </div>

                <div className='container_button_sidebar_cart'>
                    <button onClick={() => handleBtn()}>Prosseguir</button>
                </div>
            </div>
            :
            <div className='container_sidebar_cart_close'>
                <div className='header_sidebar_cart'>
                    <img src={closeIcon} onClick={() => handleStateCart(false)} />
                    <p>Meu Carrinho</p>
                </div>
                <p className='total_cart'>Total: {price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</p>
                <div className='container_button_sidebar_cart'>
                    <button>Prosseguir</button>
                </div>
            </div>
    );
}

export default SidebarCart;
