import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import companyIcon from './images/svg/companyIcon.svg'
import financialIcon from './images/svg/financialIcon.svg'
import financialCheck from './images/svg/financialCheck.svg'

import dashboardIcon from './images/svg/dashBoardIcon.svg'
import dashboardCheck from './images/svg/dashboardCheck.svg'
import exitIcon from './images/svg/exitIcon.svg'

const Sidebar = () => {
    const [option, setOption] = useState(1)

    const navigate = useNavigate()

    const exit = () => {
        localStorage.clear()
        window.location.href = "/"
    }


    return (

        <div className="sidebar">
            <img src={companyIcon} />

            {window.location.pathname == "/dashboard/account/"

                ?
                <div className='checked' onClick={() => { navigate("/dashboard/account/") }}>
                    <img src={dashboardCheck} />
                    <p>Meu perfil</p>
                </div>
                :
                <div className='not_checked' onClick={() => { navigate("/dashboard/account/") }}>
                    <img src={dashboardIcon} />
                    <p>Meu perfil</p>
                </div>

            }




            {window.location.pathname == "/dashboard/" || window.location.pathname == "/dashboard"

                ?
                <div className='checked' onClick={() => { navigate("/dashboard/") }}>
                    <img src={financialCheck} />
                    <p>Meus Pedidos</p>
                </div>
                :


                <div className='not_checked' onClick={() => { navigate("/dashboard/") }}>
                    <img src={financialIcon} />
                    <p>Meus Pedidos</p>
                </div>
            }






            <div className='not_checked' style={{ position: "absolute", bottom: 0 }} onClick={() => exit()}>
                <img src={exitIcon} style={{ width: "80px" }} />
            </div>

        </div>

    )
}

export default Sidebar