import React, { useEffect, useState, useContext } from "react";
import SelectFilterSidebar from '../SelectFilterSidebar'
import SelectFilterSidebarSecondary from '../SelectFilterSidebarSecondary'
import { ToastContainer, toast } from "react-toastify";
import MultiRangeSlider from "../MultiRangerSlider";
import api from "../../services/api";
import { FilterContext } from "../../context/filter";
const SidebarFilter = () => {

    const [userSelectValues, setUserSelectValues] = useState([])
    const [userSelectCategory, setUserSelectCategory] = useState()
    const [modelsData, setModelsData] = useState()
    const [categorysData, setCategorysData] = useState()

    const { filterProduct, handleCloseFilterProduct, storageFilter, userSelectPrimary, userSelectSecondary } = useContext(FilterContext)


    const getModels = async () => {
        const response = await api.post(`client/get/all/models/`, {
            category: userSelectSecondary.name,
            engateCategory:localStorage.getItem("@TYPE_ENGATE_FILTER") 

        })
        if (response.status == 200) {
            setModelsData(response.data)
            return false
        }else{
            setModelsData([])
        }
    }
    const getCategorys = async () => {
        const response = await api.get("client/get/all/categorys")
        if (response.status == 200) {
            setCategorysData(response.data)
        }
    }

    useEffect(() => {

        getCategorys()
    }, [])

    useEffect(() => {
        getModels()
    }, [userSelectSecondary])



    return (
        filterProduct == true

            ?


            <div className="sidebar_filter">
                <ToastContainer />

                <svg onClick={() => handleCloseFilterProduct()} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_14_486)">
                        <path d="M8.23718 7.00022L13.7436 1.49438C14.0854 1.15257 14.0854 0.598385 13.7436 0.256602C13.4018 -0.0852084 12.8476 -0.0852084 12.5059 0.256602L6.99998 5.76302L1.49413 0.256602C1.15232 -0.0852084 0.598141 -0.0852084 0.256358 0.256602C-0.0854252 0.598412 -0.0854525 1.15259 0.256358 1.49438L5.76278 7.00022L0.256358 12.5061C-0.0854525 12.8479 -0.0854525 13.4021 0.256358 13.7439C0.598168 14.0857 1.15235 14.0857 1.49413 13.7439L6.99998 8.23742L12.5058 13.7439C12.8476 14.0857 13.4018 14.0857 13.7436 13.7439C14.0854 13.4021 14.0854 12.8479 13.7436 12.5061L8.23718 7.00022Z" fill="black" />
                    </g>
                </svg>

                 <p>{localStorage.getItem("@TYPE_ENGATE_FILTER")}</p>


                <SelectFilterSidebar name={"Filtrar por marca"} data={categorysData} />
                {String(modelsData).length > 0 
                
                ?
            
                <SelectFilterSidebarSecondary name={"Filtrar  por modelo"} data={modelsData} />
                :
                <></>
                }

                {/* <MultiRangeSlider
                    min={100}
                    max={500}
                    onChange={({ min, max }) => { setUserSelectValues({ min: min, max: max }) }}
                /> */}

                <div className="footer_sidebar_filter">
                    <a href="#bottom_search"><button  onClick={() => storageFilter()}>Filtrar</button></a>
                </div>
            </div>

            :
            <></>

    )
}

export default SidebarFilter