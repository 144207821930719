import React from 'react'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
import ContainerPage from '../../components/Container'
import ContainerAccountLogin from '../../components/ContainerAccountLogin'
import HeaderMobile from '../../components/HeaderMobile'
const PaymentLogin = () => {
    return (

        <ContainerPage>
            <Header />
            <HeaderMobile/>
            <HeaderSecondary />
            <ContainerAccountLogin route={"payment/login"}/>
        </ContainerPage>

    )
}

export default PaymentLogin