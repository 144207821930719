import React, { useState, useEffect } from 'react'
import HeaderDashboardMobile from '../../components/HeaderDashboardMobile'
import HeaderDashboard from '../../components/HeaderDashboard'
import { ToastContainer, toast } from 'react-toastify';

import Sidebar from '../../components/Sidebar'
import SidebarMobile from '../../components/SidebarMobile'
import TableListMobileCategorys from '../../components/TableListMobileCategorys';
import TableListSchedules from '../../components/TableListSchedules'
import { BigHead } from '@bigheads/core'

import ThowColumLayoutDashboard from '../../layouts/ThowColumLayoutDashboard'
import InputModal from '../../components/InputModal'
import api from '../../services/api'
const Dashboard = () => {

    const [user, setUser] = useState()
    const [openSidebar, setOpenSidebar] = useState(false)


    useEffect(() => {

        const data = JSON.parse(localStorage.getItem("@USER_DATA"))
        setUser(data)


    }, [])

    return (
        <ThowColumLayoutDashboard

            colum1={<Sidebar />}
            colum2={

                <div className='container_secondary'>
                    <ToastContainer />


                    <div className='container_data'>
                        <div>
                            <HeaderDashboard name={"Meus Pedidos"} />
                            <TableListSchedules table={"1"} />

                        </div>


                    </div>

                    <div className='container_mobile'>

                        {openSidebar == true

                            ?
                            <div className='sidebar_open_background' onClick={() => setOpenSidebar(!openSidebar)}>
                            </div>
                            :
                            <></>

                        }
                        <HeaderDashboardMobile onClick={() => setOpenSidebar(true)} />
                        <SidebarMobile state={openSidebar} />

                        <div className='header_container_mobile'>
                            {/* <button className='btn_consult_mobile'>Consulta Clínica +</button> */}
                        </div>



                        <div style={{ width: "100%", background: "white", paddingLeft: "1.46rem", overflowY: "hidden" }}>
                            <div style={{ width: "90vw", overflowX: "scroll", overflowY: "hidden", position: "fixed" }}>

                                <div style={{ minWidth: "1150px", paddingLeft: "0rem", display: "flex", overflowY: "hidden" }}>
                                    <TableListMobileCategorys type={"category"} />
                                </div>
                            </div>

                        </div>

                    </div>

                </div>

            }>

        </ThowColumLayoutDashboard>
    )
}

export default Dashboard