import React, { useState, useContext, useEffect } from 'react';
import { FilterContext } from '../../context/filter';
const SelectFilterSidebar = ({ name, data }) => {

    const {
        setUserSelectSecondary,
        handleSelectVariation

    } = useContext(FilterContext)

    const [selectedOption, setSelectedOption] = useState(null);
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleOptionClick = (option) => {
        
        setUserSelectSecondary(option)
        setSelectedOption(option);
    };

    return (
        <div className='container_select_filter_sidebar' >
            <label>{name}</label>
            <div className='custom-select' onClick={() => setDropdownOpen(!dropdownOpen)}>
                <div className='selected-option'>
                    {selectedOption ? (
                        <>
                            {selectedOption.image ? (
                                <>
                                    <img src={selectedOption.image} alt={selectedOption.image} />
                                    <span>{selectedOption.name}</span>
                                </>
                            ) : (

                                <span>{selectedOption.name}</span>

                            )}
                        </>
                    ) : (
                        <span>Selecione</span>
                    )}
                </div>
                {dropdownOpen && (
                    <div className='options'>
                        {data?.map((item, index) => (
                            <div
                                key={index}
                                className='option'
                                onClick={() => handleOptionClick(item)}
                            >

                                {item.image && (
                                    <>
                                        <span className='model__options'></span>
                                        <img className='image__model_options' src={item.image} alt={item.name} />
                                    </>
                                )}
                                {item.name && <span>{item.name}</span>}

                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectFilterSidebar;
