import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
/* PAGES */

import Landing from '../pages/Landing'
import Accessories from '../pages/Accessories'
import Product from '../pages/Product'
import Account from '../pages/Account'
import AccountLogin from '../pages/AccountLogin'

import Identification from '../pages/Identification'
import PaymentLogin from '../pages/PaymentLogin'

import Offer from '../pages/Offer'
import Blog from '../pages/Blog'
import Payment from '../pages/Payment'
import PaymentProduct from '../pages/PaymentProduct'
import PrivateRoute from '../pages/PrivateRoute'
import PrivateRouteDashboard from '../pages/PrivateRouteDashboard'
import Dashboard from '../pages/Dashboard'
import DashboardAccount from '../pages/Dashboard/Account'

const AppStack = () => {
    return (

        <BrowserRouter >
            <Routes>
                <Route path='/' element={<Landing/>} />
                <Route path='/accessories' element={<Accessories/>} />
                <Route path='/product' element={<Product/>} />
                <Route path='/account' element={<Account/>} />
                <Route path='/account/register' element={<Account/>} />
                <Route path='/account/login' element={<AccountLogin/>} />
                <Route path='/offer' element={<Offer/>} />
                <Route path='/blog' element={<Blog/>} />
                <Route path='/payment' element={<Payment/>} />
                <Route path='/dashboard' element={<PrivateRouteDashboard><Dashboard/></PrivateRouteDashboard>} />
                <Route path='/dashboard/account' element={<PrivateRouteDashboard><DashboardAccount/></PrivateRouteDashboard>} />
                <Route path='/payment/identification' element={<PrivateRoute><Identification/></PrivateRoute>} />
                <Route path='/payment/identification/login' element={<PrivateRoute><PaymentLogin/></PrivateRoute>} />

                <Route path='/payment/product' element={<PaymentProduct/>} />

            </Routes>
        </BrowserRouter>



    )
}

export default AppStack
