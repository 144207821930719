import React from 'react'
import Container from '../../components/Container'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
const Blog = () => {
    return(
        <Container>
            <Header/>
            <HeaderSecondary/>
        
        </Container>
    )
}

export default Blog