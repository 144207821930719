import React from 'react'
import ContainterPageAcessories from '../../components/ContainterPageAcessories'
import Header from '../../components/Header'
import HeaderSecondary from '../../components/HeaderSecondary'
const Offer = () => {
    return (

        <ContainterPageAcessories>
            <Header />
            <HeaderSecondary />
        </ContainterPageAcessories>

    )
}

export default Offer