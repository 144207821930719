import React, { useEffect,useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import api from '../../services/api';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-coverflow';

// import required modules
import { Pagination, Autoplay, EffectCoverflow } from 'swiper/modules';

const VerticalCarrousel = () => {

    const [banners, setBanners] = useState([]);

    const getBanner = async () => {

        const response = await api.get("client/banner/3")
        console.log(response.data)
        if (response.status == 200) {
            setBanners(response.data)
        }

    }

    useEffect(() => {

        getBanner()

    }, [])


    return (
        <div className='container_vertical_carrousel'>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={1}
                spaceBetween={-150}
                direction='vertical'
                speed={1500}
                coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 200,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[EffectCoverflow, Pagination, Autoplay]}
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
                className="mySwiper"
            >

                {banners?.map(item => {
                    return (
                        <SwiperSlide >
                            <div style={{ height: "30px" }}></div>
                            <img src={item.image} alt="Imagem 1" />
                        </SwiperSlide>
                    )
                })}

                {/* Adicione mais SwiperSlide se necessário */}
            </Swiper>
        </div>
    );
};

export default VerticalCarrousel;
