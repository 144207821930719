import React, { useEffect, useState } from 'react'
import cartIcon from '../../images/png/add-to-cart.png'
import ReactStars from 'react-stars'
import api from '../../services/api'
import { json, useNavigate } from 'react-router-dom'

const Card = ({ description, price, avaliations, product_image, image, topImage, onClick, product_id, category_image, name, promotion_price, weight, index }) => {

    const [centralImage, setCentralImage] = useState();

    const navigate = useNavigate();

    const getImages = async () => {
        try {
            const response = await api.get(`client/get/all/images/${product_id}`);
            if (response.status === 200) {
                setCentralImage(response.data[0].image);
            }
        } catch (error) {
            console.error('Error fetching images:', error);
        }
    };

    const redirectToProductPage = async () => {
        try {

            const variation  = await api.get(`client/get/variation/${product_id}`)

            if(variation.status == 200){
                localStorage.setItem("@PRODUCT_VARIATIONS", JSON.stringify(variation.data));
                localStorage.setItem("@PRODUCT_VARIATIONS_IMAGES", JSON.stringify(variation.data.variations_images));
            }

            const response = await api.get(`client/get/all/images/product/${product_id}`);
            if (response.status === 200) {
                localStorage.setItem("@PRODUCT_IMAGES", JSON.stringify(response.data));
                localStorage.setItem("@NAME", name);
                localStorage.setItem("@PRODUCT_ID", product_id);
                localStorage.setItem("@DESCRIPTION", description);
                localStorage.setItem("@PRICE", price);
                localStorage.setItem("@PROMOTIONAL_PRICE", promotion_price);
                localStorage.setItem("@WEIGHT", weight);
                localStorage.setItem("@IMAGE", image);

                window.location.href="/product";
            }
            window.location.href="/product";


        } catch (error) {
            console.error('Error redirecting to product page:', error);
        }
    };

    useEffect(() => {
        getImages();
    }, [product_id]);
    return (


        <>
            <div className='card'  >


                <div className='descount_card' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>
                    <p>15% off</p>

                </div>

                <div className='container_top_image_card' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>
                    <img className='top_image_card' src={category_image} />
                </div>

                <img className='center_image_card' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)} src={product_image} />
                <p className='text_card_description' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>{name}</p>
                <p className='text_card_price' onClick={() => redirectToProductPage(description, price, image, name, promotion_price, weight)}>R$ {price}</p>

                <div className='row_button_cart'>
                    <ReactStars
                        count={5}
                        value={4.3}
                        size={24}
                        edit={false}
                        color2={'#ffd700'} />
                    <button onClick={onClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3 3H3.26835C3.74213 3 3.97943 3 4.17267 3.08548C4.34304 3.16084 4.48871 3.28218 4.59375 3.43604C4.71269 3.61026 4.75564 3.8429 4.84137 4.30727L7.00004 16L17.4218 16C17.875 16 18.1023 16 18.29 15.9199C18.4559 15.8492 18.5989 15.7346 18.7051 15.5889C18.8252 15.4242 18.8761 15.2037 18.9777 14.7631L18.9785 14.76L20.5477 7.95996L20.5481 7.95854C20.7023 7.29016 20.7796 6.95515 20.6947 6.69238C20.6202 6.46182 20.4635 6.26634 20.2556 6.14192C20.0184 6 19.6758 6 18.9887 6H5.5M18 21C17.4477 21 17 20.5523 17 20C17 19.4477 17.4477 19 18 19C18.5523 19 19 19.4477 19 20C19 20.5523 18.5523 21 18 21ZM8 21C7.44772 21 7 20.5523 7 20C7 19.4477 7.44772 19 8 19C8.55228 19 9 19.4477 9 20C9 20.5523 8.55228 21 8 21Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        Adicionar +</button>
                </div>

            </div>
        </>

    )
}

export default Card